.hero {
  position: relative;
  width: 100%;
  height: 35vh; /* Adjust based on your preference */
  overflow: hidden;
  margin-top: 125px;
}

.hero-img {
  /* transform: translateY(15%); */
  width: 100%;
  height: 100%;
  opacity: 80%;
  object-fit: cover; /* Ensure the image covers the area */
  object-position: center; /* Center the image */
  position: absolute;
  top: 0;
  left: 0;
}

.hero-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background: rgba(0, 0, 0, 0.5); */
  z-index: 1; /* Ensure it's above the image */
}

.hero-text {
  position: absolute;
  top: 50%;
  left: 50%;
  color: var(--dl-color-custom-neutral-dark);
  transform: translate(-50%, -50%);
  text-align: center;
  z-index: 2; /* Ensure text is above the image and overlay */
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .hero {
    height: 300px; /* Smaller height on mobile devices */
  }

  .hero-text h1 {
    font-size: 24px; /* Smaller text on mobile devices */
  }

  .hero-text p {
    font-size: 16px;
  }
}

.home-btn-group {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: center;
  flex-direction: row;
}

.home-btn-group {
  flex-direction: column;
}

.hero-sub-heading {
  font-size: 20px;
  margin-bottom: 20px;
}