/* Reuse basic styles from home.css for consistency */
.home-container {
  font-family: 'Roboto', sans-serif;
  /* color: #333;
  max-width: 1200px; */
  margin: 0 auto;
}

/* Adapt hero section for Services page */
.services-hero-container {
  background: #f5f5f5;
  margin-bottom: 30px;
}

.services-heading {
  font-size: 2.5em;
  color: var(--dl-color-custom-neutral-dark);
  margin-bottom: 10px;
}

.services-intro {
  font-size: 1.2em;
  margin-bottom: 20px;
}

/* Style for service items */
.services-item {
  margin-bottom: 30px;
}

.service-title {
  font-size: 1.8em;
  color: var(--dl-color-custom-neutral-dark);
}

.service-description {
  font-size: 1em;
  margin-top: 10px;
}

/* Inclusions list */
.service-inclusions {
  list-style: inside square;
  text-align: left;
  margin-top: 10px;
  padding: 0 20%;
}

/* Footer styling adapted from home.css */
.services-footer1 {
  background-color: #f5f5f5;
  color: #333;
  padding: 20px;
  text-align: center;
}

.email-text {
  margin-top: 100px;
  /* margin-bottom: 100px; */
  font-size: 1.3em;
  font-weight: bold;
  color: var(--dl-color-custom-neutral-dark);

  max-width: 90vw;
}

@media (max-width: 768px) {

  .email-text {
    margin-top: 50px;
    /* margin-bottom: 100px; */
    font-size: 1.0em;
    font-weight: bold;
    color: var(--dl-color-custom-neutral-dark);
  
    max-width: 90vw;
  }
}